import { Grid } from "@mui/material";
import _ from "lodash";
import AudioButton from "./AudioButton";
import BaseCard from "./BaseCard";
import { CsScenario } from "./data";

interface ScenarioGridItemProps {
  scenario: CsScenario
}

function ScenarioGridItem({ scenario }: ScenarioGridItemProps) {
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
      <BaseCard title={`Scenario ${scenario.icon? "" : scenario.id}`} icon={scenario.icon}>
        {scenario.sections.map(section => (
          <AudioButton size="small" thin={scenario.sections.length > 5} key={`sec-${scenario.id}-${section.name}`} data={{
            name: `Scenario ${scenario.id} ${_.startCase(section.name)}`,
            url: section.url,
            directUrl: section.directUrl
          }}>
            {section.name}
          </AudioButton>
        ))}
      </BaseCard>
    </Grid>
  );
}

export default ScenarioGridItem;