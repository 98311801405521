import { Grid } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import AudioButton from "./AudioButton";
import BaseCard from "./BaseCard";
import { CsEvent } from "./data";

interface EventGridItemProps {
  event: CsEvent
}

function EventGridItem({ event }: EventGridItemProps) {
  const eventName = (event: CsEvent) => `${_.startCase(event.type)} Event ${event.id.toUpperCase()}`;
  const [name] = useState(eventName(event));
  const [frontData] = useState({
    name: name,
    url: event.frontUrl,
    directUrl: event.frontDirectUrl
  });
  const [outaData] = useState({
    name: `${name} A`,
    url: event.outaUrl,
    directUrl: event.outaDirectUrl
  });
  const [outbData] = useState({
    name: `${name} B`,
    url: event.outbUrl,
    directUrl: event.outbDirectUrl
  });

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
      <BaseCard title={name}>
        <AudioButton data={frontData}>
          Front
        </AudioButton>
        <AudioButton data={outaData}>
          A
        </AudioButton>
        <AudioButton data={outbData}>
          B
        </AudioButton>
      </BaseCard>
    </Grid>
  );
}

export default EventGridItem;