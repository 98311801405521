import { CsEvent } from "./CsEvent";

export const roadEvents: CsEvent[] = [
  {
    "type": "road",
    "id": "01",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434763-crimson-scales-re-road-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434754-crimson-scales-re-road-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434751-crimson-scales-re-road-01-outb.mp3"
  },
  {
    "type": "road",
    "id": "02",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434748-crimson-scales-re-road-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434736-crimson-scales-re-road-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434733-crimson-scales-re-road-02-outb.mp3"
  },
  {
    "type": "road",
    "id": "03",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-03-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-03-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-03-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434727-crimson-scales-re-road-03-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434724-crimson-scales-re-road-03-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434721-crimson-scales-re-road-03-outb.mp3"
  },
  {
    "type": "road",
    "id": "04",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-04-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-04-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-04-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434718-crimson-scales-re-road-04-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434715-crimson-scales-re-road-04-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434709-crimson-scales-re-road-04-outb.mp3"
  },
  {
    "type": "road",
    "id": "05",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-05-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-05-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-05-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434703-crimson-scales-re-road-05-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434694-crimson-scales-re-road-05-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434691-crimson-scales-re-road-05-outb.mp3"
  },
  {
    "type": "road",
    "id": "06",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-06-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-06-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-06-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434688-crimson-scales-re-road-06-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434682-crimson-scales-re-road-06-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434679-crimson-scales-re-road-06-outb.mp3"
  },
  {
    "type": "road",
    "id": "07",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-07-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-07-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-07-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434676-crimson-scales-re-road-07-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434670-crimson-scales-re-road-07-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434667-crimson-scales-re-road-07-outb.mp3"
  },
  {
    "type": "road",
    "id": "08",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-08-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-08-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-08-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434664-crimson-scales-re-road-08-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434661-crimson-scales-re-road-08-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434655-crimson-scales-re-road-08-outb.mp3"
  },
  {
    "type": "road",
    "id": "09",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-09-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-09-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-09-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434649-crimson-scales-re-road-09-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434643-crimson-scales-re-road-09-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434640-crimson-scales-re-road-09-outb.mp3"
  },
  {
    "type": "road",
    "id": "10",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-10-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-10-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-10-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434637-crimson-scales-re-road-10-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434634-crimson-scales-re-road-10-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434631-crimson-scales-re-road-10-outb.mp3"
  },
  {
    "type": "road",
    "id": "11",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-11-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-11-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-11-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434628-crimson-scales-re-road-11-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434625-crimson-scales-re-road-11-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434622-crimson-scales-re-road-11-outb.mp3"
  },
  {
    "type": "road",
    "id": "12",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-12-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-12-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-12-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434619-crimson-scales-re-road-12-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434616-crimson-scales-re-road-12-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434607-crimson-scales-re-road-12-outb.mp3"
  },
  {
    "type": "road",
    "id": "13",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-13-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-13-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-13-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434604-crimson-scales-re-road-13-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434601-crimson-scales-re-road-13-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434598-crimson-scales-re-road-13-outb.mp3"
  },
  {
    "type": "road",
    "id": "14",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-14-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-14-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-14-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434595-crimson-scales-re-road-14-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434592-crimson-scales-re-road-14-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434586-crimson-scales-re-road-14-outb.mp3"
  },
  {
    "type": "road",
    "id": "15",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-15-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-15-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-15-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434583-crimson-scales-re-road-15-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434580-crimson-scales-re-road-15-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434577-crimson-scales-re-road-15-outb.mp3"
  },
  {
    "type": "road",
    "id": "16",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-16-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-16-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-16-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434568-crimson-scales-re-road-16-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434562-crimson-scales-re-road-16-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434556-crimson-scales-re-road-16-outb.mp3"
  },
  {
    "type": "road",
    "id": "17",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-17-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-17-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-17-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434553-crimson-scales-re-road-17-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434541-crimson-scales-re-road-17-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434535-crimson-scales-re-road-17-outb.mp3"
  },
  {
    "type": "road",
    "id": "18",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-18-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-18-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-18-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434532-crimson-scales-re-road-18-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434529-crimson-scales-re-road-18-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434526-crimson-scales-re-road-18-outb.mp3"
  },
  {
    "type": "road",
    "id": "19",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-19-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-19-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-19-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434517-crimson-scales-re-road-19-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434511-crimson-scales-re-road-19-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434505-crimson-scales-re-road-19-outb.mp3"
  },
  {
    "type": "road",
    "id": "20",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-20-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-20-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-20-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273434502-crimson-scales-re-road-20-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273434499-crimson-scales-re-road-20-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273434493-crimson-scales-re-road-20-outb.mp3"
  },
  {
    "type": "road",
    "id": "21",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-21-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-21-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-21-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437457-crimson-scales-re-road-21-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437451-crimson-scales-re-road-21-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437448-crimson-scales-re-road-21-outb.mp3"
  },
  {
    "type": "road",
    "id": "22",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-22-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-22-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-22-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437439-crimson-scales-re-road-22-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437430-crimson-scales-re-road-22-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437427-crimson-scales-re-road-22-outb.mp3"
  },
  {
    "type": "road",
    "id": "23",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-23-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-23-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-23-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437424-crimson-scales-re-road-23-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437421-crimson-scales-re-road-23-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437415-crimson-scales-re-road-23-outb.mp3"
  },
  {
    "type": "road",
    "id": "24",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-24-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-24-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-24-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437406-crimson-scales-re-road-24-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437400-crimson-scales-re-road-24-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437397-crimson-scales-re-road-24-outb.mp3"
  },
  {
    "type": "road",
    "id": "25",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-25-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-25-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-25-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437394-crimson-scales-re-road-25-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437391-crimson-scales-re-road-25-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437388-crimson-scales-re-road-25-outb.mp3"
  },
  {
    "type": "road",
    "id": "26",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-26-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-26-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-26-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437382-crimson-scales-re-road-26-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437379-crimson-scales-re-road-26-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437376-crimson-scales-re-road-26-outb.mp3"
  },
  {
    "type": "road",
    "id": "27",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-27-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-27-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-27-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437373-crimson-scales-re-road-27-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437370-crimson-scales-re-road-27-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437364-crimson-scales-re-road-27-outb.mp3"
  },
  {
    "type": "road",
    "id": "28",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-28-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-28-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-28-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437361-crimson-scales-re-road-28-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437358-crimson-scales-re-road-28-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437352-crimson-scales-re-road-28-outb.mp3"
  },
  {
    "type": "road",
    "id": "29",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-29-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-29-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-29-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437343-crimson-scales-re-road-29-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437340-crimson-scales-re-road-29-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437337-crimson-scales-re-road-29-outb.mp3"
  },
  {
    "type": "road",
    "id": "30",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-30-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-30-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-30-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437334-crimson-scales-re-road-30-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437331-crimson-scales-re-road-30-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437325-crimson-scales-re-road-30-outb.mp3"
  },
  {
    "type": "road",
    "id": "31",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-31-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-31-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-31-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437322-crimson-scales-re-road-31-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437310-crimson-scales-re-road-31-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437301-crimson-scales-re-road-31-outb.mp3"
  },
  {
    "type": "road",
    "id": "32",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-32-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-32-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-32-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437295-crimson-scales-re-road-32-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437283-crimson-scales-re-road-32-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437277-crimson-scales-re-road-32-outb.mp3"
  },
  {
    "type": "road",
    "id": "33",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-33-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-33-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-33-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437274-crimson-scales-re-road-33-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437268-crimson-scales-re-road-33-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437265-crimson-scales-re-road-33-outb.mp3"
  },
  {
    "type": "road",
    "id": "34",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-34-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-34-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-34-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437259-crimson-scales-re-road-34-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437256-crimson-scales-re-road-34-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437250-crimson-scales-re-road-34-outb.mp3"
  },
  {
    "type": "road",
    "id": "35",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-35-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-35-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-35-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437247-crimson-scales-re-road-35-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437244-crimson-scales-re-road-35-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437241-crimson-scales-re-road-35-outb.mp3"
  },
  {
    "type": "road",
    "id": "36",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-36-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-36-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-36-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437232-crimson-scales-re-road-36-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437229-crimson-scales-re-road-36-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437226-crimson-scales-re-road-36-outb.mp3"
  },
  {
    "type": "road",
    "id": "37",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-37-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-37-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-37-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437220-crimson-scales-re-road-37-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437211-crimson-scales-re-road-37-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437202-crimson-scales-re-road-37-outb.mp3"
  },
  {
    "type": "road",
    "id": "38",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-38-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-38-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-38-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437199-crimson-scales-re-road-38-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437193-crimson-scales-re-road-38-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437187-crimson-scales-re-road-38-outb.mp3"
  },
  {
    "type": "road",
    "id": "39",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-39-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-39-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-39-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437184-crimson-scales-re-road-39-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437178-crimson-scales-re-road-39-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437175-crimson-scales-re-road-39-outb.mp3"
  },
  {
    "type": "road",
    "id": "40",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-40-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-40-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-40-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273437166-crimson-scales-re-road-40-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273437163-crimson-scales-re-road-40-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273437157-crimson-scales-re-road-40-outb.mp3"
  },
  {
    "type": "road",
    "id": "41",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-41-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-41-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-41-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447636-crimson-scales-re-road-41-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447633-crimson-scales-re-road-41-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447630-crimson-scales-re-road-41-outb.mp3"
  },
  {
    "type": "road",
    "id": "42",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-42-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-42-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-42-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447624-crimson-scales-re-road-42-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447621-crimson-scales-re-road-42-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447618-crimson-scales-re-road-42-outb.mp3"
  },
  {
    "type": "road",
    "id": "43",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-43-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-43-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-43-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447615-crimson-scales-re-road-43-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447609-crimson-scales-re-road-43-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447606-crimson-scales-re-road-43-outb.mp3"
  },
  {
    "type": "road",
    "id": "44",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-44-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-44-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-44-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447600-crimson-scales-re-road-44-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447597-crimson-scales-re-road-44-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447594-crimson-scales-re-road-44-outb.mp3"
  },
  {
    "type": "road",
    "id": "45",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-45-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-45-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-45-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447591-crimson-scales-re-road-45-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447582-crimson-scales-re-road-45-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447579-crimson-scales-re-road-45-outb.mp3"
  },
  {
    "type": "road",
    "id": "46",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-46-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-46-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-46-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447576-crimson-scales-re-road-46-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447573-crimson-scales-re-road-46-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447570-crimson-scales-re-road-46-outb.mp3"
  },
  {
    "type": "road",
    "id": "47",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-47-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-47-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-47-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447567-crimson-scales-re-road-47-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447564-crimson-scales-re-road-47-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447561-crimson-scales-re-road-47-outb.mp3"
  },
  {
    "type": "road",
    "id": "48",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-48-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-48-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-48-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447555-crimson-scales-re-road-48-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447552-crimson-scales-re-road-48-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447549-crimson-scales-re-road-48-outb.mp3"
  },
  {
    "type": "road",
    "id": "49",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-49-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-49-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-49-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447546-crimson-scales-re-road-49-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447540-crimson-scales-re-road-49-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447537-crimson-scales-re-road-49-outb.mp3"
  },
  {
    "type": "road",
    "id": "50",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-50-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-50-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-50-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447534-crimson-scales-re-road-50-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447531-crimson-scales-re-road-50-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447525-crimson-scales-re-road-50-outb.mp3"
  },
  {
    "type": "road",
    "id": "51",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-51-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-51-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-51-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447519-crimson-scales-re-road-51-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447513-crimson-scales-re-road-51-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447507-crimson-scales-re-road-51-outb.mp3"
  },
  {
    "type": "road",
    "id": "52",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-52-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-52-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-52-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447504-crimson-scales-re-road-52-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447501-crimson-scales-re-road-52-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447498-crimson-scales-re-road-52-outb.mp3"
  },
  {
    "type": "road",
    "id": "53",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-53-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-53-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-53-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447486-crimson-scales-re-road-53-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447483-crimson-scales-re-road-53-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447480-crimson-scales-re-road-53-outb.mp3"
  },
  {
    "type": "road",
    "id": "54",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-54-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-54-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-54-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447471-crimson-scales-re-road-54-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447465-crimson-scales-re-road-54-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447459-crimson-scales-re-road-54-outb.mp3"
  },
  {
    "type": "road",
    "id": "55",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-55-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-55-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-55-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447456-crimson-scales-re-road-55-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447453-crimson-scales-re-road-55-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447447-crimson-scales-re-road-55-outb.mp3"
  },
  {
    "type": "road",
    "id": "56",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-56-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-56-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-56-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447444-crimson-scales-re-road-56-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447441-crimson-scales-re-road-56-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447438-crimson-scales-re-road-56-outb.mp3"
  },
  {
    "type": "road",
    "id": "57",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-57-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-57-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-57-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447435-crimson-scales-re-road-57-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447432-crimson-scales-re-road-57-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447423-crimson-scales-re-road-57-outb.mp3"
  },
  {
    "type": "road",
    "id": "58",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-58-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-58-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-58-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447417-crimson-scales-re-road-58-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447414-crimson-scales-re-road-58-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447408-crimson-scales-re-road-58-outb.mp3"
  },
  {
    "type": "road",
    "id": "59",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-59-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-59-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-59-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447402-crimson-scales-re-road-59-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447399-crimson-scales-re-road-59-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447396-crimson-scales-re-road-59-outb.mp3"
  },
  {
    "type": "road",
    "id": "60",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-60-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-60-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-60-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447393-crimson-scales-re-road-60-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447390-crimson-scales-re-road-60-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447384-crimson-scales-re-road-60-outb.mp3"
  },
  {
    "type": "road",
    "id": "aa-01",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-aa-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-aa-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-aa-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273448035-crimson-scales-re-road-aa-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273448029-crimson-scales-re-road-aa-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273448026-crimson-scales-re-road-aa-01-outb.mp3"
  },
  {
    "type": "road",
    "id": "aa-02",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-aa-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-aa-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-aa-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273448020-crimson-scales-re-road-aa-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273448014-crimson-scales-re-road-aa-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273448008-crimson-scales-re-road-aa-02-outb.mp3"
  },
  {
    "type": "road",
    "id": "qa-01",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-qa-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-qa-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-qa-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273448005-crimson-scales-re-road-qa-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273448002-crimson-scales-re-road-qa-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447993-crimson-scales-re-road-qa-01-outb.mp3"
  },
  {
    "type": "road",
    "id": "qa-02",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-qa-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-qa-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-qa-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447990-crimson-scales-re-road-qa-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447987-crimson-scales-re-road-qa-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447984-crimson-scales-re-road-qa-02-outb.mp3"
  },
  {
    "type": "road",
    "id": "rm-01",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-rm-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-rm-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-rm-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447981-crimson-scales-re-road-rm-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447972-crimson-scales-re-road-rm-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447969-crimson-scales-re-road-rm-01-outb.mp3"
  },
  {
    "type": "road",
    "id": "rm-02",
    "frontUrl": "https://soundcloud.com/crimson-scales-re/road-rm-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-re/road-rm-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-re/road-rm-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273447966-crimson-scales-re-road-rm-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273447963-crimson-scales-re-road-rm-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273447957-crimson-scales-re-road-rm-02-outb.mp3"
  }
];