import { Button, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { AudioButtonData, AUDIO_BUTTON_CLICK_EVENT, EventBus } from "./events";

interface AudioButtonProps {
  data: AudioButtonData
  children: ReactNode,
  size?: "small" | "medium" | "large"
  thin?: boolean
}

function AudioButton({data, children, size, thin}:AudioButtonProps) {
  const handleClick = () => {
    EventBus.dispatch(AUDIO_BUTTON_CLICK_EVENT, data);
  }

  const sx: SxProps<Theme> = { fontSize: 'calc(0.2vw + 10px)' }
  if(thin) {
    sx.minWidth = "30px !important"
  }

  return (
    <Button sx={sx} variant="contained" onClick={handleClick} size={size}>
      {children}
    </Button>
  )
}

export default AudioButton;