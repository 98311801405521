import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: 'dark',
    text: {
      primary: '#fff',
    },
    primary: {
      main: '#b71c1c',
    },
    background: {
      default: '#000',
      paper: '#222',
    },
  },
});

export default theme;