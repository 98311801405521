export const EventBus = {
  on(eventType: string, callback: EventListener) {
    document.addEventListener(eventType, callback);
  },
  dispatch(eventType: string, data: any) {
    document.dispatchEvent(new CustomEvent(eventType, { detail: data }));
  },
  remove(eventType: string, callback: EventListener) {
    document.removeEventListener(eventType, callback);
  },
  detail(event: Event): any {
    return (event as CustomEvent).detail;
  }
};