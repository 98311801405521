import { CsScenario } from './CsScenario';

export const scenarios: CsScenario[] = [
  {
    "id": "01",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-01-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399525-crimson-scales-scenario-01-intro.mp3"
      },
      {
        "name": "2C",
        "url": "https://soundcloud.com/crimson-scales/scenario-01-2C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399531-crimson-scales-scenario-01-2c.mp3"
      }
    ]
  },
  {
    "id": "02",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-02-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399513-crimson-scales-scenario-02-intro.mp3"
      },
      {
        "name": "3A",
        "url": "https://soundcloud.com/crimson-scales/scenario-02-3A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399519-crimson-scales-scenario-02-3a.mp3"
      },
      {
        "name": "3C",
        "url": "https://soundcloud.com/crimson-scales/scenario-02-3C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399516-crimson-scales-scenario-02-3c.mp3"
      }
    ]
  },
  {
    "id": "03",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-03-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399504-crimson-scales-scenario-03-intro.mp3"
      },
      {
        "name": "4A",
        "url": "https://soundcloud.com/crimson-scales/scenario-03-4A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399510-crimson-scales-scenario-03-4a.mp3"
      }
    ]
  },
  {
    "id": "04",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-04-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399480-crimson-scales-scenario-04-intro.mp3"
      },
      {
        "name": "4B",
        "url": "https://soundcloud.com/crimson-scales/scenario-04-4B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399501-crimson-scales-scenario-04-4b.mp3"
      },
      {
        "name": "6A",
        "url": "https://soundcloud.com/crimson-scales/scenario-04-6A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399492-crimson-scales-scenario-04-6a.mp3"
      }
    ]
  },
  {
    "id": "05",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-05-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399465-crimson-scales-scenario-05-intro.mp3"
      },
      {
        "name": "6C",
        "url": "https://soundcloud.com/crimson-scales/scenario-05-6C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399477-crimson-scales-scenario-05-6c.mp3"
      },
      {
        "name": "8A",
        "url": "https://soundcloud.com/crimson-scales/scenario-05-8A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399471-crimson-scales-scenario-05-8a.mp3"
      }
    ]
  },
  {
    "id": "06",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-06-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399444-crimson-scales-scenario-06-intro.mp3"
      },
      {
        "name": "6D",
        "url": "https://soundcloud.com/crimson-scales/scenario-06-6D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399462-crimson-scales-scenario-06-6d.mp3"
      },
      {
        "name": "6E",
        "url": "https://soundcloud.com/crimson-scales/scenario-06-6E",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399459-crimson-scales-scenario-06-6e.mp3"
      },
      {
        "name": "6F",
        "url": "https://soundcloud.com/crimson-scales/scenario-06-6F",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399456-crimson-scales-scenario-06-6f.mp3"
      },
      {
        "name": "6G",
        "url": "https://soundcloud.com/crimson-scales/scenario-06-6G",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399453-crimson-scales-scenario-06-6g.mp3"
      },
      {
        "name": "7A",
        "url": "https://soundcloud.com/crimson-scales/scenario-06-7A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399450-crimson-scales-scenario-06-7a.mp3"
      }
    ]
  },
  {
    "id": "07",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-07-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399438-crimson-scales-scenario-07-intro.mp3"
      },
      {
        "name": "7B",
        "url": "https://soundcloud.com/crimson-scales/scenario-07-7B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399441-crimson-scales-scenario-07-7b.mp3"
      }
    ]
  },
  {
    "id": "08",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-08-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399423-crimson-scales-scenario-08-intro.mp3"
      },
      {
        "name": "7D",
        "url": "https://soundcloud.com/crimson-scales/scenario-08-7D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399429-crimson-scales-scenario-08-7d.mp3"
      }
    ]
  },
  {
    "id": "09",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-09-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399414-crimson-scales-scenario-09-intro.mp3"
      },
      {
        "name": "8C",
        "url": "https://soundcloud.com/crimson-scales/scenario-09-8C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399417-crimson-scales-scenario-09-8c.mp3"
      },
      {
        "name": "10B",
        "url": "https://soundcloud.com/crimson-scales/scenario-09-10B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399420-crimson-scales-scenario-09-10b.mp3"
      }
    ]
  },
  {
    "id": "10",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-10-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399405-crimson-scales-scenario-10-intro.mp3"
      },
      {
        "name": "12C",
        "url": "https://soundcloud.com/crimson-scales/scenario-10-12C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273399411-crimson-scales-scenario-10-12c.mp3"
      }
    ]
  },
  {
    "id": "11",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-11-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401220-crimson-scales-scenario-11-intro.mp3"
      },
      {
        "name": "13B",
        "url": "https://soundcloud.com/crimson-scales/scenario-11-13B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401232-crimson-scales-scenario-11-13b.mp3"
      },
      {
        "name": "13D",
        "url": "https://soundcloud.com/crimson-scales/scenario-11-13D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401223-crimson-scales-scenario-11-13d.mp3"
      },
      {
        "name": "13C",
        "url": "https://soundcloud.com/crimson-scales/scenario-11-13C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401229-crimson-scales-scenario-11-13c.mp3"
      }
    ]
  },
  {
    "id": "12",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-12-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401211-crimson-scales-scenario-12-intro.mp3"
      },
      {
        "name": "13G",
        "url": "https://soundcloud.com/crimson-scales/scenario-12-13G",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401214-crimson-scales-scenario-12-13g.mp3"
      }
    ]
  },
  {
    "id": "13",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-13-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401202-crimson-scales-scenario-13-intro.mp3"
      },
      {
        "name": "14B",
        "url": "https://soundcloud.com/crimson-scales/scenario-13-14B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401208-crimson-scales-scenario-13-14b.mp3"
      }
    ]
  },
  {
    "id": "14",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-14-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401190-crimson-scales-scenario-14-intro.mp3"
      },
      {
        "name": "15A",
        "url": "https://soundcloud.com/crimson-scales/scenario-14-15A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401199-crimson-scales-scenario-14-15a.mp3"
      },
      {
        "name": "16A",
        "url": "https://soundcloud.com/crimson-scales/scenario-14-16A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401193-crimson-scales-scenario-14-16a.mp3"
      }
    ]
  },
  {
    "id": "15",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-15-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401184-crimson-scales-scenario-15-intro.mp3"
      },
      {
        "name": "16C",
        "url": "https://soundcloud.com/crimson-scales/scenario-15-16C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401187-crimson-scales-scenario-15-16c.mp3"
      }
    ]
  },
  {
    "id": "16",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-16-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401172-crimson-scales-scenario-16-intro.mp3"
      },
      {
        "name": "16B",
        "url": "https://soundcloud.com/crimson-scales/scenario-16-16B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401181-crimson-scales-scenario-16-16b.mp3"
      },
      {
        "name": "17D",
        "url": "https://soundcloud.com/crimson-scales/scenario-16-17D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401178-crimson-scales-scenario-16-17d.mp3"
      }
    ]
  },
  {
    "id": "17",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-17-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401160-crimson-scales-scenario-17-intro.mp3"
      },
      {
        "name": "17B",
        "url": "https://soundcloud.com/crimson-scales/scenario-17-17B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401169-crimson-scales-scenario-17-17b.mp3"
      }
    ]
  },
  {
    "id": "18",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-18-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401151-crimson-scales-scenario-18-intro.mp3"
      },
      {
        "name": "18D",
        "url": "https://soundcloud.com/crimson-scales/scenario-18-18D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401157-crimson-scales-scenario-18-18d.mp3"
      }
    ]
  },
  {
    "id": "19",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-19-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401139-crimson-scales-scenario-19-intro.mp3"
      },
      {
        "name": "19B",
        "url": "https://soundcloud.com/crimson-scales/scenario-19-19B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401148-crimson-scales-scenario-19-19b.mp3"
      }
    ]
  },
  {
    "id": "20",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-20-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401127-crimson-scales-scenario-20-intro.mp3"
      },
      {
        "name": "20B",
        "url": "https://soundcloud.com/crimson-scales/scenario-20-20B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401133-crimson-scales-scenario-20-20b.mp3"
      }
    ]
  },
  {
    "id": "21",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-21-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401628-crimson-scales-scenario-21-intro.mp3"
      },
      {
        "name": "21B",
        "url": "https://soundcloud.com/crimson-scales/scenario-21-21B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401637-crimson-scales-scenario-21-21b.mp3"
      },
      {
        "name": "21C",
        "url": "https://soundcloud.com/crimson-scales/scenario-21-21C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401634-crimson-scales-scenario-21-21c.mp3"
      }
    ]
  },
  {
    "id": "22",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-22-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401613-crimson-scales-scenario-22-intro.mp3"
      },
      {
        "name": "23B",
        "url": "https://soundcloud.com/crimson-scales/scenario-22-23B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401619-crimson-scales-scenario-22-23b.mp3"
      }
    ]
  },
  {
    "id": "23",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-23-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401601-crimson-scales-scenario-23-intro.mp3"
      },
      {
        "name": "24B",
        "url": "https://soundcloud.com/crimson-scales/scenario-23-24B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401604-crimson-scales-scenario-23-24b.mp3"
      }
    ]
  },
  {
    "id": "24",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-24-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401577-crimson-scales-scenario-24-intro.mp3"
      },
      {
        "name": "24C",
        "url": "https://soundcloud.com/crimson-scales/scenario-24-24C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401598-crimson-scales-scenario-24-24c.mp3"
      },
      {
        "name": "25A",
        "url": "https://soundcloud.com/crimson-scales/scenario-24-25A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401595-crimson-scales-scenario-24-25a.mp3"
      },
      {
        "name": "25B",
        "url": "https://soundcloud.com/crimson-scales/scenario-24-25B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401586-crimson-scales-scenario-24-25b.mp3"
      },
      {
        "name": "26A",
        "url": "https://soundcloud.com/crimson-scales/scenario-24-26A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401583-crimson-scales-scenario-24-26a.mp3"
      }
    ]
  },
  {
    "id": "25",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-25-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401565-crimson-scales-scenario-25-intro.mp3"
      },
      {
        "name": "26B",
        "url": "https://soundcloud.com/crimson-scales/scenario-25-26B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401571-crimson-scales-scenario-25-26b.mp3"
      }
    ]
  },
  {
    "id": "26",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-26-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401550-crimson-scales-scenario-26-intro.mp3"
      },
      {
        "name": "27C",
        "url": "https://soundcloud.com/crimson-scales/scenario-26-27C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401562-crimson-scales-scenario-26-27c.mp3"
      },
      {
        "name": "28A",
        "url": "https://soundcloud.com/crimson-scales/scenario-26-28A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401556-crimson-scales-scenario-26-28a.mp3"
      },
      {
        "name": "28B",
        "url": "https://soundcloud.com/crimson-scales/scenario-26-28B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401553-crimson-scales-scenario-26-28b.mp3"
      }
    ]
  },
  {
    "id": "27",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-27-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401535-crimson-scales-scenario-27-intro.mp3"
      },
      {
        "name": "29A",
        "url": "https://soundcloud.com/crimson-scales/scenario-27-29A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401541-crimson-scales-scenario-27-29a.mp3"
      },
      {
        "name": "29B",
        "url": "https://soundcloud.com/crimson-scales/scenario-27-29B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401538-crimson-scales-scenario-27-29b.mp3"
      }
    ]
  },
  {
    "id": "28",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-28-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401520-crimson-scales-scenario-28-intro.mp3"
      },
      {
        "name": "30B",
        "url": "https://soundcloud.com/crimson-scales/scenario-28-30B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401529-crimson-scales-scenario-28-30b.mp3"
      },
      {
        "name": "30C",
        "url": "https://soundcloud.com/crimson-scales/scenario-28-30C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401526-crimson-scales-scenario-28-30c.mp3"
      }
    ]
  },
  {
    "id": "29",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-29-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401511-crimson-scales-scenario-29-intro.mp3"
      },
      {
        "name": "28C",
        "url": "https://soundcloud.com/crimson-scales/scenario-29-28C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401514-crimson-scales-scenario-29-28c.mp3"
      }
    ]
  },
  {
    "id": "30",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-30-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401472-crimson-scales-scenario-30-intro.mp3"
      },
      {
        "name": "32A",
        "url": "https://soundcloud.com/crimson-scales/scenario-30-32A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401499-crimson-scales-scenario-30-32a.mp3"
      },
      {
        "name": "32B",
        "url": "https://soundcloud.com/crimson-scales/scenario-30-32B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401496-crimson-scales-scenario-30-32b.mp3"
      },
      {
        "name": "32C",
        "url": "https://soundcloud.com/crimson-scales/scenario-30-32C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401490-crimson-scales-scenario-30-32c.mp3"
      },
      {
        "name": "33A",
        "url": "https://soundcloud.com/crimson-scales/scenario-30-33A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401481-crimson-scales-scenario-30-33a.mp3"
      },
      {
        "name": "34A",
        "url": "https://soundcloud.com/crimson-scales/scenario-30-34A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273401475-crimson-scales-scenario-30-34a.mp3"
      }
    ]
  },
  {
    "id": "31",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-31-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402213-crimson-scales-scenario-31-intro.mp3"
      },
      {
        "name": "34B",
        "url": "https://soundcloud.com/crimson-scales/scenario-31-34B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402216-crimson-scales-scenario-31-34b.mp3"
      }
    ]
  },
  {
    "id": "32",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-32-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402198-crimson-scales-scenario-32-intro.mp3"
      },
      {
        "name": "34C",
        "url": "https://soundcloud.com/crimson-scales/scenario-32-34C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402204-crimson-scales-scenario-32-34c.mp3"
      }
    ]
  },
  {
    "id": "33",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-33-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402180-crimson-scales-scenario-33-intro.mp3"
      },
      {
        "name": "36A",
        "url": "https://soundcloud.com/crimson-scales/scenario-33-36A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402195-crimson-scales-scenario-33-36a.mp3"
      },
      {
        "name": "36C",
        "url": "https://soundcloud.com/crimson-scales/scenario-33-36C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402189-crimson-scales-scenario-33-36c.mp3"
      },
      {
        "name": "37A",
        "url": "https://soundcloud.com/crimson-scales/scenario-33-37A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402186-crimson-scales-scenario-33-37a.mp3"
      }
    ]
  },
  {
    "id": "34",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-34-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402159-crimson-scales-scenario-34-intro.mp3"
      },
      {
        "name": "36B",
        "url": "https://soundcloud.com/crimson-scales/scenario-34-36B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402177-crimson-scales-scenario-34-36b.mp3"
      },
      {
        "name": "37C",
        "url": "https://soundcloud.com/crimson-scales/scenario-34-37C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402174-crimson-scales-scenario-34-37c.mp3"
      },
      {
        "name": "38B",
        "url": "https://soundcloud.com/crimson-scales/scenario-34-38B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402162-crimson-scales-scenario-34-38b.mp3"
      }
    ]
  },
  {
    "id": "35",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-35-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402150-crimson-scales-scenario-35-intro.mp3"
      },
      {
        "name": "38C",
        "url": "https://soundcloud.com/crimson-scales/scenario-35-38C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402156-crimson-scales-scenario-35-38c.mp3"
      }
    ]
  },
  {
    "id": "36",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-36-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402144-crimson-scales-scenario-36-intro.mp3"
      },
      {
        "name": "41A",
        "url": "https://soundcloud.com/crimson-scales/scenario-36-41A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402147-crimson-scales-scenario-36-41a.mp3"
      }
    ]
  },
  {
    "id": "37",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-37-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402132-crimson-scales-scenario-37-intro.mp3"
      },
      {
        "name": "41B",
        "url": "https://soundcloud.com/crimson-scales/scenario-37-41B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402138-crimson-scales-scenario-37-41b.mp3"
      },
      {
        "name": "42A",
        "url": "https://soundcloud.com/crimson-scales/scenario-37-42A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402135-crimson-scales-scenario-37-42a.mp3"
      }
    ]
  },
  {
    "id": "38",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-38-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402111-crimson-scales-scenario-38-intro.mp3"
      },
      {
        "name": "42B",
        "url": "https://soundcloud.com/crimson-scales/scenario-38-42B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402129-crimson-scales-scenario-38-42b.mp3"
      },
      {
        "name": "43A",
        "url": "https://soundcloud.com/crimson-scales/scenario-38-43A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402126-crimson-scales-scenario-38-43a.mp3"
      },
      {
        "name": "43B",
        "url": "https://soundcloud.com/crimson-scales/scenario-38-43B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402123-crimson-scales-scenario-38-43b.mp3"
      },
      {
        "name": "43C",
        "url": "https://soundcloud.com/crimson-scales/scenario-38-43C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402117-crimson-scales-scenario-38-43c.mp3"
      }
    ]
  },
  {
    "id": "39",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-39-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402090-crimson-scales-scenario-39-intro.mp3"
      },
      {
        "name": "44A",
        "url": "https://soundcloud.com/crimson-scales/scenario-39-44A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402099-crimson-scales-scenario-39-44a.mp3"
      },
      {
        "name": "44B",
        "url": "https://soundcloud.com/crimson-scales/scenario-39-44B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402096-crimson-scales-scenario-39-44b.mp3"
      },
      {
        "name": "45A",
        "url": "https://soundcloud.com/crimson-scales/scenario-39-45A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402093-crimson-scales-scenario-39-45a.mp3"
      }
    ]
  },
  {
    "id": "40",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-40-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402066-crimson-scales-scenario-40-intro.mp3"
      },
      {
        "name": "44C",
        "url": "https://soundcloud.com/crimson-scales/scenario-40-44C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402081-crimson-scales-scenario-40-44c.mp3"
      },
      {
        "name": "45C",
        "url": "https://soundcloud.com/crimson-scales/scenario-40-45C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273402075-crimson-scales-scenario-40-45c.mp3"
      }
    ]
  },
  {
    "id": "41",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-41-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403980-crimson-scales-scenario-41-intro.mp3"
      },
      {
        "name": "45B",
        "url": "https://soundcloud.com/crimson-scales/scenario-41-45B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403989-crimson-scales-scenario-41-45b.mp3"
      },
      {
        "name": "46A",
        "url": "https://soundcloud.com/crimson-scales/scenario-41-46A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403986-crimson-scales-scenario-41-46a.mp3"
      }
    ]
  },
  {
    "id": "42",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-42-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403956-crimson-scales-scenario-42-intro.mp3"
      },
      {
        "name": "45D",
        "url": "https://soundcloud.com/crimson-scales/scenario-42-45D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403977-crimson-scales-scenario-42-45d.mp3"
      },
      {
        "name": "46B",
        "url": "https://soundcloud.com/crimson-scales/scenario-42-46B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403971-crimson-scales-scenario-42-46b.mp3"
      },
      {
        "name": "47A",
        "url": "https://soundcloud.com/crimson-scales/scenario-42-47A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403968-crimson-scales-scenario-42-47a.mp3"
      },
      {
        "name": "47B",
        "url": "https://soundcloud.com/crimson-scales/scenario-42-47B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403959-crimson-scales-scenario-42-47b.mp3"
      }
    ]
  },
  {
    "id": "43",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-43-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403944-crimson-scales-scenario-43-intro.mp3"
      },
      {
        "name": "48B",
        "url": "https://soundcloud.com/crimson-scales/scenario-43-48B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403947-crimson-scales-scenario-43-48b.mp3"
      }
    ]
  },
  {
    "id": "44",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-44-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403914-crimson-scales-scenario-44-intro.mp3"
      },
      {
        "name": "49A",
        "url": "https://soundcloud.com/crimson-scales/scenario-44-49A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403941-crimson-scales-scenario-44-49a.mp3"
      },
      {
        "name": "49B",
        "url": "https://soundcloud.com/crimson-scales/scenario-44-49B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403926-crimson-scales-scenario-44-49b.mp3"
      },
      {
        "name": "49C",
        "url": "https://soundcloud.com/crimson-scales/scenario-44-49C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403920-crimson-scales-scenario-44-49c.mp3"
      },
      {
        "name": "52A",
        "url": "https://soundcloud.com/crimson-scales/scenario-44-52A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403917-crimson-scales-scenario-44-52a.mp3"
      }
    ]
  },
  {
    "id": "45",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-45-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403899-crimson-scales-scenario-45-intro.mp3"
      },
      {
        "name": "50A",
        "url": "https://soundcloud.com/crimson-scales/scenario-45-50A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403908-crimson-scales-scenario-45-50a.mp3"
      },
      {
        "name": "51B",
        "url": "https://soundcloud.com/crimson-scales/scenario-45-51B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403905-crimson-scales-scenario-45-51b.mp3"
      }
    ]
  },
  {
    "id": "46",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-46-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403887-crimson-scales-scenario-46-intro.mp3"
      },
      {
        "name": "51A",
        "url": "https://soundcloud.com/crimson-scales/scenario-46-51A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403896-crimson-scales-scenario-46-51a.mp3"
      },
      {
        "name": "51C",
        "url": "https://soundcloud.com/crimson-scales/scenario-46-51C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403893-crimson-scales-scenario-46-51c.mp3"
      },
      {
        "name": "52C",
        "url": "https://soundcloud.com/crimson-scales/scenario-46-52C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403890-crimson-scales-scenario-46-52c.mp3"
      }
    ]
  },
  {
    "id": "47",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-47-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403878-crimson-scales-scenario-47-intro.mp3"
      },
      {
        "name": "54C",
        "url": "https://soundcloud.com/crimson-scales/scenario-47-54C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403884-crimson-scales-scenario-47-54c.mp3"
      }
    ]
  },
  {
    "id": "48",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-48-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403866-crimson-scales-scenario-48-intro.mp3"
      },
      {
        "name": "56A",
        "url": "https://soundcloud.com/crimson-scales/scenario-48-56A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403872-crimson-scales-scenario-48-56a.mp3"
      }
    ]
  },
  {
    "id": "49",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-49-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403854-crimson-scales-scenario-49-intro.mp3"
      },
      {
        "name": "55A",
        "url": "https://soundcloud.com/crimson-scales/scenario-49-55A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403863-crimson-scales-scenario-49-55a.mp3"
      },
      {
        "name": "55C",
        "url": "https://soundcloud.com/crimson-scales/scenario-49-55C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403857-crimson-scales-scenario-49-55c.mp3"
      }
    ]
  },
  {
    "id": "50",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-50-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403839-crimson-scales-scenario-50-intro.mp3"
      },
      {
        "name": "56B",
        "url": "https://soundcloud.com/crimson-scales/scenario-50-56B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403845-crimson-scales-scenario-50-56b.mp3"
      },
      {
        "name": "56C",
        "url": "https://soundcloud.com/crimson-scales/scenario-50-56C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273403842-crimson-scales-scenario-50-56c.mp3"
      }
    ]
  },
  {
    "id": "51",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-51-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413757-crimson-scales-scenario-51-intro.mp3"
      },
      {
        "name": "58A",
        "url": "https://soundcloud.com/crimson-scales/scenario-51-58A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413760-crimson-scales-scenario-51-58a.mp3"
      }
    ]
  },
  {
    "id": "52",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-52-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413733-crimson-scales-scenario-52-intro.mp3"
      },
      {
        "name": "57B",
        "url": "https://soundcloud.com/crimson-scales/scenario-52-57B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413754-crimson-scales-scenario-52-57b.mp3"
      },
      {
        "name": "58B",
        "url": "https://soundcloud.com/crimson-scales/scenario-52-58B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413751-crimson-scales-scenario-52-58b.mp3"
      },
      {
        "name": "58C",
        "url": "https://soundcloud.com/crimson-scales/scenario-52-58C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413745-crimson-scales-scenario-52-58c.mp3"
      },
      {
        "name": "59A",
        "url": "https://soundcloud.com/crimson-scales/scenario-52-59A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413736-crimson-scales-scenario-52-59a.mp3"
      }
    ]
  },
  {
    "id": "53",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-53-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413700-crimson-scales-scenario-53-intro.mp3"
      },
      {
        "name": "59B",
        "url": "https://soundcloud.com/crimson-scales/scenario-53-59B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413724-crimson-scales-scenario-53-59b.mp3"
      },
      {
        "name": "59C",
        "url": "https://soundcloud.com/crimson-scales/scenario-53-59C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413721-crimson-scales-scenario-53-59c.mp3"
      },
      {
        "name": "59D",
        "url": "https://soundcloud.com/crimson-scales/scenario-53-59D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413715-crimson-scales-scenario-53-59d.mp3"
      },
      {
        "name": "60A",
        "url": "https://soundcloud.com/crimson-scales/scenario-53-60A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413712-crimson-scales-scenario-53-60a.mp3"
      },
      {
        "name": "61A",
        "url": "https://soundcloud.com/crimson-scales/scenario-53-61A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413706-crimson-scales-scenario-53-61a.mp3"
      }
    ]
  },
  {
    "id": "54",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-54-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413688-crimson-scales-scenario-54-intro.mp3"
      },
      {
        "name": "61B",
        "url": "https://soundcloud.com/crimson-scales/scenario-54-61B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413697-crimson-scales-scenario-54-61b.mp3"
      },
      {
        "name": "62A",
        "url": "https://soundcloud.com/crimson-scales/scenario-54-62A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413694-crimson-scales-scenario-54-62a.mp3"
      },
      {
        "name": "62B",
        "url": "https://soundcloud.com/crimson-scales/scenario-54-62B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413691-crimson-scales-scenario-54-62b.mp3"
      }
    ]
  },
  {
    "id": "55",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-55-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413664-crimson-scales-scenario-55-intro.mp3"
      },
      {
        "name": "62C",
        "url": "https://soundcloud.com/crimson-scales/scenario-55-62C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413685-crimson-scales-scenario-55-62c.mp3"
      },
      {
        "name": "63A",
        "url": "https://soundcloud.com/crimson-scales/scenario-55-63A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413679-crimson-scales-scenario-55-63a.mp3"
      },
      {
        "name": "64A",
        "url": "https://soundcloud.com/crimson-scales/scenario-55-64A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413670-crimson-scales-scenario-55-64a.mp3"
      }
    ]
  },
  {
    "id": "56",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-56-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413652-crimson-scales-scenario-56-intro.mp3"
      },
      {
        "name": "63B",
        "url": "https://soundcloud.com/crimson-scales/scenario-56-63B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413661-crimson-scales-scenario-56-63b.mp3"
      },
      {
        "name": "64B",
        "url": "https://soundcloud.com/crimson-scales/scenario-56-64B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413655-crimson-scales-scenario-56-64b.mp3"
      }
    ]
  },
  {
    "id": "57",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-57-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413634-crimson-scales-scenario-57-intro.mp3"
      },
      {
        "name": "65D",
        "url": "https://soundcloud.com/crimson-scales/scenario-57-65D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413643-crimson-scales-scenario-57-65d.mp3"
      }
    ]
  },
  {
    "id": "58",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-58-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413613-crimson-scales-scenario-58-intro.mp3"
      },
      {
        "name": "66E",
        "url": "https://soundcloud.com/crimson-scales/scenario-58-66E",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413628-crimson-scales-scenario-58-66e.mp3"
      },
      {
        "name": "66F",
        "url": "https://soundcloud.com/crimson-scales/scenario-58-66F",
        "directUrl": "https://feeds.soundcloud.com/stream/1273413619-crimson-scales-scenario-58-66f.mp3"
      }
    ]
  },
  {
    "id": "59",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-59-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414744-crimson-scales-scenario-59-intro.mp3"
      },
      {
        "name": "67A",
        "url": "https://soundcloud.com/crimson-scales/scenario-59-67A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414756-crimson-scales-scenario-59-67a.mp3"
      },
      {
        "name": "67B",
        "url": "https://soundcloud.com/crimson-scales/scenario-59-67B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414750-crimson-scales-scenario-59-67b.mp3"
      },
      {
        "name": "67C",
        "url": "https://soundcloud.com/crimson-scales/scenario-59-67C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414747-crimson-scales-scenario-59-67c.mp3"
      }
    ]
  },
  {
    "id": "60",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-60-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414714-crimson-scales-scenario-60-intro.mp3"
      },
      {
        "name": "67D",
        "url": "https://soundcloud.com/crimson-scales/scenario-60-67D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414738-crimson-scales-scenario-60-67d.mp3"
      },
      {
        "name": "68A",
        "url": "https://soundcloud.com/crimson-scales/scenario-60-68A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414732-crimson-scales-scenario-60-68a.mp3"
      },
      {
        "name": "68B",
        "url": "https://soundcloud.com/crimson-scales/scenario-60-68B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414729-crimson-scales-scenario-60-68b.mp3"
      },
      {
        "name": "69A",
        "url": "https://soundcloud.com/crimson-scales/scenario-60-69A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414723-crimson-scales-scenario-60-69a.mp3"
      },
      {
        "name": "69B",
        "url": "https://soundcloud.com/crimson-scales/scenario-60-69B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414720-crimson-scales-scenario-60-69b.mp3"
      }
    ]
  },
  {
    "id": "61",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-61-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414699-crimson-scales-scenario-61-intro.mp3"
      },
      {
        "name": "69C",
        "url": "https://soundcloud.com/crimson-scales/scenario-61-69C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414711-crimson-scales-scenario-61-69c.mp3"
      },
      {
        "name": "69E",
        "url": "https://soundcloud.com/crimson-scales/scenario-61-69E",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414705-crimson-scales-scenario-61-69e.mp3"
      }
    ]
  },
  {
    "id": "62",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-62-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414663-crimson-scales-scenario-62-intro.mp3"
      },
      {
        "name": "70A",
        "url": "https://soundcloud.com/crimson-scales/scenario-62-70A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414693-crimson-scales-scenario-62-70a.mp3"
      },
      {
        "name": "70B",
        "url": "https://soundcloud.com/crimson-scales/scenario-62-70B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414687-crimson-scales-scenario-62-70b.mp3"
      },
      {
        "name": "71A",
        "url": "https://soundcloud.com/crimson-scales/scenario-62-71A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414678-crimson-scales-scenario-62-71a.mp3"
      },
      {
        "name": "71B",
        "url": "https://soundcloud.com/crimson-scales/scenario-62-71B",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414675-crimson-scales-scenario-62-71b.mp3"
      },
      {
        "name": "71C",
        "url": "https://soundcloud.com/crimson-scales/scenario-62-71C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414666-crimson-scales-scenario-62-71c.mp3"
      }
    ]
  },
  {
    "id": "63",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-63-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414654-crimson-scales-scenario-63-intro.mp3"
      },
      {
        "name": "72A",
        "url": "https://soundcloud.com/crimson-scales/scenario-63-72A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414660-crimson-scales-scenario-63-72a.mp3"
      }
    ]
  },
  {
    "id": "64",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-64-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414645-crimson-scales-scenario-64-intro.mp3"
      },
      {
        "name": "73A",
        "url": "https://soundcloud.com/crimson-scales/scenario-64-73A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414651-crimson-scales-scenario-64-73a.mp3"
      }
    ]
  },
  {
    "id": "65",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-65-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414624-crimson-scales-scenario-65-intro.mp3"
      },
      {
        "name": "73C",
        "url": "https://soundcloud.com/crimson-scales/scenario-65-73C",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414642-crimson-scales-scenario-65-73c.mp3"
      },
      {
        "name": "73D",
        "url": "https://soundcloud.com/crimson-scales/scenario-65-73D",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414636-crimson-scales-scenario-65-73d.mp3"
      },
      {
        "name": "74A",
        "url": "https://soundcloud.com/crimson-scales/scenario-65-74A",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414633-crimson-scales-scenario-65-74a.mp3"
      }
    ]
  },
  {
    "id": "66",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales/scenario-66-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414612-crimson-scales-scenario-66-intro.mp3"
      },
      {
        "name": "74E",
        "url": "https://soundcloud.com/crimson-scales/scenario-66-74E",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414621-crimson-scales-scenario-66-74e.mp3"
      },
      {
        "name": "74G",
        "url": "https://soundcloud.com/crimson-scales/scenario-66-74G",
        "directUrl": "https://feeds.soundcloud.com/stream/1273414618-crimson-scales-scenario-66-74g.mp3"
      }
    ]
  },
  {
    "id": "AA1",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-AA1-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411702-crimson-scales-sa-scenario-aa1-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-AA1-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411708-crimson-scales-sa-scenario-aa1-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-AA1-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411705-crimson-scales-sa-scenario-aa1-2.mp3"
      }
    ]
  },
  {
    "id": "AA2",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-AA2-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411672-crimson-scales-sa-scenario-aa2-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-AA2-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411696-crimson-scales-sa-scenario-aa2-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-AA2-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411687-crimson-scales-sa-scenario-aa2-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-AA2-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411675-crimson-scales-sa-scenario-aa2-3.mp3"
      }
    ]
  },
  {
    "id": "Beetle",
    "icon": "icons/beetle.svg",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-Beetle-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411651-crimson-scales-sa-scenario-beetle-intro.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-Beetle-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411654-crimson-scales-sa-scenario-beetle-2.mp3"
      }
    ]
  },
  {
    "id": "QA1",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411561-crimson-scales-sa-scenario-qa1-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411609-crimson-scales-sa-scenario-qa1-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411606-crimson-scales-sa-scenario-qa1-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411597-crimson-scales-sa-scenario-qa1-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411588-crimson-scales-sa-scenario-qa1-4.mp3"
      },
      {
        "name": "5",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-5",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411585-crimson-scales-sa-scenario-qa1-5.mp3"
      },
      {
        "name": "6",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-6",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411573-crimson-scales-sa-scenario-qa1-6.mp3"
      },
      {
        "name": "7",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA1-7",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411570-crimson-scales-sa-scenario-qa1-7.mp3"
      }
    ]
  },
  {
    "id": "QA2",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411501-crimson-scales-sa-scenario-qa2-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411558-crimson-scales-sa-scenario-qa2-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411546-crimson-scales-sa-scenario-qa2-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411543-crimson-scales-sa-scenario-qa2-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411540-crimson-scales-sa-scenario-qa2-4.mp3"
      },
      {
        "name": "5",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-5",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411531-crimson-scales-sa-scenario-qa2-5.mp3"
      },
      {
        "name": "6",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-6",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411516-crimson-scales-sa-scenario-qa2-6.mp3"
      },
      {
        "name": "7",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-7",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411507-crimson-scales-sa-scenario-qa2-7.mp3"
      },
      {
        "name": "8",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-QA2-8",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411504-crimson-scales-sa-scenario-qa2-8.mp3"
      }
    ]
  },
  {
    "id": "Tools",
    "icon": "icons/tools.svg",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-Tools-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411315-crimson-scales-sa-scenario-tools-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-Tools-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411336-crimson-scales-sa-scenario-tools-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-Tools-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411333-crimson-scales-sa-scenario-tools-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-Tools-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411330-crimson-scales-sa-scenario-tools-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-Tools-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411324-crimson-scales-sa-scenario-tools-4.mp3"
      }
    ]
  },
  {
    "id": "RM1",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM1-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411480-crimson-scales-sa-scenario-rm1-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM1-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411498-crimson-scales-sa-scenario-rm1-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM1-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411489-crimson-scales-sa-scenario-rm1-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM1-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411486-crimson-scales-sa-scenario-rm1-3.mp3"
      }
    ]
  },
  {
    "id": "RM2",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM2-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411453-crimson-scales-sa-scenario-rm2-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM2-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411477-crimson-scales-sa-scenario-rm2-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM2-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411468-crimson-scales-sa-scenario-rm2-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM2-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411456-crimson-scales-sa-scenario-rm2-3.mp3"
      }
    ]
  },
  {
    "id": "RM3",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM3-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411426-crimson-scales-sa-scenario-rm3-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM3-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411444-crimson-scales-sa-scenario-rm3-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM3-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411438-crimson-scales-sa-scenario-rm3-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM3-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411432-crimson-scales-sa-scenario-rm3-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM3-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411429-crimson-scales-sa-scenario-rm3-4.mp3"
      }
    ]
  },
  {
    "id": "RM4",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM4-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411393-crimson-scales-sa-scenario-rm4-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM4-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411414-crimson-scales-sa-scenario-rm4-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM4-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411411-crimson-scales-sa-scenario-rm4-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM4-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411405-crimson-scales-sa-scenario-rm4-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM4-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411402-crimson-scales-sa-scenario-rm4-4.mp3"
      }
    ]
  },
  {
    "id": "RM5",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM5-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411366-crimson-scales-sa-scenario-rm5-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM5-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411390-crimson-scales-sa-scenario-rm5-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM5-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411387-crimson-scales-sa-scenario-rm5-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM5-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411384-crimson-scales-sa-scenario-rm5-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM5-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411378-crimson-scales-sa-scenario-rm5-4.mp3"
      },
      {
        "name": "5",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM5-5",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411375-crimson-scales-sa-scenario-rm5-5.mp3"
      },
      {
        "name": "6",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM5-6",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411372-crimson-scales-sa-scenario-rm5-6.mp3"
      }
    ]
  },
  {
    "id": "Bleeding Claw",
    "icon": "icons/bleeding-claw.svg",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-BleedingClaw-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411612-crimson-scales-sa-scenario-bleedingclaw-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-BleedingClaw-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411642-crimson-scales-sa-scenario-bleedingclaw-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-BleedingClaw-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411639-crimson-scales-sa-scenario-bleedingclaw-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-BleedingClaw-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411633-crimson-scales-sa-scenario-bleedingclaw-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-BleedingClaw-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411627-crimson-scales-sa-scenario-bleedingclaw-4.mp3"
      },
      {
        "name": "5",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-BleedingClaw-5",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411618-crimson-scales-sa-scenario-bleedingclaw-5.mp3"
      }
    ]
  },
  {
    "id": "RM7",
    "sections": [
      {
        "name": "intro",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM7-intro",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411339-crimson-scales-sa-scenario-rm7-intro.mp3"
      },
      {
        "name": "1",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM7-1",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411363-crimson-scales-sa-scenario-rm7-1.mp3"
      },
      {
        "name": "2",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM7-2",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411360-crimson-scales-sa-scenario-rm7-2.mp3"
      },
      {
        "name": "3",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM7-3",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411357-crimson-scales-sa-scenario-rm7-3.mp3"
      },
      {
        "name": "4",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM7-4",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411354-crimson-scales-sa-scenario-rm7-4.mp3"
      },
      {
        "name": "5",
        "url": "https://soundcloud.com/crimson-scales-sa/scenario-RM7-5",
        "directUrl": "https://feeds.soundcloud.com/stream/1273411342-crimson-scales-sa-scenario-rm7-5.mp3"
      }
    ]
  }
];