export const PLAYER_MODE = "player";
export const BOT_MODE = "bot";

export const BONGO_BOT = "bongo";
export const CHIP_BOT = "chip";
export const FRED_BOAT_BOT = "fredboat";
export const HYDRA_BOT = "hydra";
export const GREEN_BOT = "green";
export const JOCKIE_MUSIC_BOT = "jockiemusic";
export const MAKI_BOT = "maki";
export const PANCAKE_BOT = "pancake";
export const PRO_BOT = "probot";
export const SOUL_MUSIC_BOT = "souldmusic";
export const CUSTOM_BOT: string = "custom";

export const BOT_COMMANDS = new Map<string, string>([
  [BONGO_BOT, "b.play"],
  [CHIP_BOT, "ch!play"],
  [FRED_BOAT_BOT, ";;play"],
  [GREEN_BOT, "/play"],
  [JOCKIE_MUSIC_BOT, "m!p"],
  [HYDRA_BOT, ".play"],
  [MAKI_BOT, "/play"],
  [PANCAKE_BOT, "p!play"],
  [PRO_BOT, "/play"],
  [SOUL_MUSIC_BOT, "/play"],
]);

export const KEY_CODE_SPACE = "Space";
export const KEY_CODE_BACKSPACE = "Backspace";
export const KEY_CODE_ESCAPE = "Escape";