import { CsEvent } from "./CsEvent";

export const cityEvents: CsEvent[] = [
  {
    "type": "city",
    "id": "01",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422079-crimson-scales-ce-city-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422076-crimson-scales-ce-city-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422073-crimson-scales-ce-city-01-outb.mp3"
  },
  {
    "type": "city",
    "id": "02",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422070-crimson-scales-ce-city-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422064-crimson-scales-ce-city-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422061-crimson-scales-ce-city-02-outb.mp3"
  },
  {
    "type": "city",
    "id": "03",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-03-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-03-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-03-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422058-crimson-scales-ce-city-03-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422052-crimson-scales-ce-city-03-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422049-crimson-scales-ce-city-03-outb.mp3"
  },
  {
    "type": "city",
    "id": "04",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-04-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-04-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-04-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422046-crimson-scales-ce-city-04-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422037-crimson-scales-ce-city-04-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422031-crimson-scales-ce-city-04-outb.mp3"
  },
  {
    "type": "city",
    "id": "05",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-05-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-05-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-05-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422025-crimson-scales-ce-city-05-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422022-crimson-scales-ce-city-05-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422016-crimson-scales-ce-city-05-outb.mp3"
  },
  {
    "type": "city",
    "id": "06",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-06-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-06-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-06-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422013-crimson-scales-ce-city-06-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422007-crimson-scales-ce-city-06-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422001-crimson-scales-ce-city-06-outb.mp3"
  },
  {
    "type": "city",
    "id": "07",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-07-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-07-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-07-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421986-crimson-scales-ce-city-07-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421983-crimson-scales-ce-city-07-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421977-crimson-scales-ce-city-07-outb.mp3"
  },
  {
    "type": "city",
    "id": "08",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-08-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-08-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-08-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421974-crimson-scales-ce-city-08-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421971-crimson-scales-ce-city-08-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421965-crimson-scales-ce-city-08-outb.mp3"
  },
  {
    "type": "city",
    "id": "09",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-09-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-09-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-09-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421962-crimson-scales-ce-city-09-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421959-crimson-scales-ce-city-09-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421956-crimson-scales-ce-city-09-outb.mp3"
  },
  {
    "type": "city",
    "id": "10",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-10-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-10-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-10-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421950-crimson-scales-ce-city-10-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421938-crimson-scales-ce-city-10-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421932-crimson-scales-ce-city-10-outb.mp3"
  },
  {
    "type": "city",
    "id": "11",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-11-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-11-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-11-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421929-crimson-scales-ce-city-11-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421926-crimson-scales-ce-city-11-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421920-crimson-scales-ce-city-11-outb.mp3"
  },
  {
    "type": "city",
    "id": "12",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-12-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-12-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-12-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421914-crimson-scales-ce-city-12-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421899-crimson-scales-ce-city-12-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421893-crimson-scales-ce-city-12-outb.mp3"
  },
  {
    "type": "city",
    "id": "13",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-13-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-13-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-13-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421887-crimson-scales-ce-city-13-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421878-crimson-scales-ce-city-13-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421872-crimson-scales-ce-city-13-outb.mp3"
  },
  {
    "type": "city",
    "id": "14",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-14-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-14-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-14-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421869-crimson-scales-ce-city-14-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421866-crimson-scales-ce-city-14-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421863-crimson-scales-ce-city-14-outb.mp3"
  },
  {
    "type": "city",
    "id": "15",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-15-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-15-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-15-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421857-crimson-scales-ce-city-15-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421854-crimson-scales-ce-city-15-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421848-crimson-scales-ce-city-15-outb.mp3"
  },
  {
    "type": "city",
    "id": "16",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-16-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-16-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-16-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421839-crimson-scales-ce-city-16-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421836-crimson-scales-ce-city-16-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421833-crimson-scales-ce-city-16-outb.mp3"
  },
  {
    "type": "city",
    "id": "17",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-17-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-17-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-17-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421827-crimson-scales-ce-city-17-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421821-crimson-scales-ce-city-17-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421818-crimson-scales-ce-city-17-outb.mp3"
  },
  {
    "type": "city",
    "id": "18",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-18-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-18-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-18-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421812-crimson-scales-ce-city-18-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421806-crimson-scales-ce-city-18-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421800-crimson-scales-ce-city-18-outb.mp3"
  },
  {
    "type": "city",
    "id": "19",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-19-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-19-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-19-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421794-crimson-scales-ce-city-19-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421779-crimson-scales-ce-city-19-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421773-crimson-scales-ce-city-19-outb.mp3"
  },
  {
    "type": "city",
    "id": "20",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-20-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-20-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-20-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273421770-crimson-scales-ce-city-20-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273421767-crimson-scales-ce-city-20-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273421764-crimson-scales-ce-city-20-outb.mp3"
  },
  {
    "type": "city",
    "id": "21",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-21-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-21-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-21-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273423090-crimson-scales-ce-city-21-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273423084-crimson-scales-ce-city-21-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273423081-crimson-scales-ce-city-21-outb.mp3"
  },
  {
    "type": "city",
    "id": "22",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-22-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-22-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-22-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273423078-crimson-scales-ce-city-22-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273423075-crimson-scales-ce-city-22-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273423069-crimson-scales-ce-city-22-outb.mp3"
  },
  {
    "type": "city",
    "id": "23",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-23-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-23-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-23-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273423063-crimson-scales-ce-city-23-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273423060-crimson-scales-ce-city-23-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273423051-crimson-scales-ce-city-23-outb.mp3"
  },
  {
    "type": "city",
    "id": "24",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-24-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-24-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-24-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273423048-crimson-scales-ce-city-24-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273423042-crimson-scales-ce-city-24-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273423039-crimson-scales-ce-city-24-outb.mp3"
  },
  {
    "type": "city",
    "id": "25",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-25-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-25-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-25-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273423036-crimson-scales-ce-city-25-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273423027-crimson-scales-ce-city-25-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273423024-crimson-scales-ce-city-25-outb.mp3"
  },
  {
    "type": "city",
    "id": "26",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-26-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-26-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-26-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273423015-crimson-scales-ce-city-26-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273423012-crimson-scales-ce-city-26-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273423006-crimson-scales-ce-city-26-outb.mp3"
  },
  {
    "type": "city",
    "id": "27",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-27-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-27-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-27-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273423003-crimson-scales-ce-city-27-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273423000-crimson-scales-ce-city-27-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422997-crimson-scales-ce-city-27-outb.mp3"
  },
  {
    "type": "city",
    "id": "28",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-28-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-28-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-28-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422991-crimson-scales-ce-city-28-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422988-crimson-scales-ce-city-28-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422985-crimson-scales-ce-city-28-outb.mp3"
  },
  {
    "type": "city",
    "id": "29",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-29-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-29-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-29-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422982-crimson-scales-ce-city-29-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422976-crimson-scales-ce-city-29-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422973-crimson-scales-ce-city-29-outb.mp3"
  },
  {
    "type": "city",
    "id": "30",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-30-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-30-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-30-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422967-crimson-scales-ce-city-30-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422964-crimson-scales-ce-city-30-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422958-crimson-scales-ce-city-30-outb.mp3"
  },
  {
    "type": "city",
    "id": "31",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-31-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-31-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-31-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422955-crimson-scales-ce-city-31-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422952-crimson-scales-ce-city-31-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422949-crimson-scales-ce-city-31-outb.mp3"
  },
  {
    "type": "city",
    "id": "32",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-32-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-32-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-32-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422946-crimson-scales-ce-city-32-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422943-crimson-scales-ce-city-32-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422937-crimson-scales-ce-city-32-outb.mp3"
  },
  {
    "type": "city",
    "id": "33",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-33-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-33-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-33-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422934-crimson-scales-ce-city-33-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422928-crimson-scales-ce-city-33-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422922-crimson-scales-ce-city-33-outb.mp3"
  },
  {
    "type": "city",
    "id": "34",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-34-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-34-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-34-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422919-crimson-scales-ce-city-34-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422913-crimson-scales-ce-city-34-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422907-crimson-scales-ce-city-34-outb.mp3"
  },
  {
    "type": "city",
    "id": "35",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-35-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-35-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-35-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422904-crimson-scales-ce-city-35-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422901-crimson-scales-ce-city-35-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422898-crimson-scales-ce-city-35-outb.mp3"
  },
  {
    "type": "city",
    "id": "36",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-36-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-36-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-36-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422895-crimson-scales-ce-city-36-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422892-crimson-scales-ce-city-36-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422889-crimson-scales-ce-city-36-outb.mp3"
  },
  {
    "type": "city",
    "id": "37",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-37-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-37-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-37-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422883-crimson-scales-ce-city-37-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422877-crimson-scales-ce-city-37-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422874-crimson-scales-ce-city-37-outb.mp3"
  },
  {
    "type": "city",
    "id": "38",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-38-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-38-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-38-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422868-crimson-scales-ce-city-38-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422859-crimson-scales-ce-city-38-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422853-crimson-scales-ce-city-38-outb.mp3"
  },
  {
    "type": "city",
    "id": "39",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-39-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-39-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-39-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422850-crimson-scales-ce-city-39-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422847-crimson-scales-ce-city-39-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422841-crimson-scales-ce-city-39-outb.mp3"
  },
  {
    "type": "city",
    "id": "40",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-40-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-40-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-40-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273422829-crimson-scales-ce-city-40-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273422823-crimson-scales-ce-city-40-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273422820-crimson-scales-ce-city-40-outb.mp3"
  },
  {
    "type": "city",
    "id": "41",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-41-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-41-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-41-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438606-crimson-scales-ce-city-41-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438603-crimson-scales-ce-city-41-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438597-crimson-scales-ce-city-41-outb.mp3"
  },
  {
    "type": "city",
    "id": "42",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-42-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-42-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-42-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438594-crimson-scales-ce-city-42-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438591-crimson-scales-ce-city-42-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438585-crimson-scales-ce-city-42-outb.mp3"
  },
  {
    "type": "city",
    "id": "43",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-43-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-43-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-43-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438582-crimson-scales-ce-city-43-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438579-crimson-scales-ce-city-43-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438576-crimson-scales-ce-city-43-outb.mp3"
  },
  {
    "type": "city",
    "id": "44",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-44-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-44-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-44-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438573-crimson-scales-ce-city-44-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438567-crimson-scales-ce-city-44-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438564-crimson-scales-ce-city-44-outb.mp3"
  },
  {
    "type": "city",
    "id": "45",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-45-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-45-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-45-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438561-crimson-scales-ce-city-45-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438558-crimson-scales-ce-city-45-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438555-crimson-scales-ce-city-45-outb.mp3"
  },
  {
    "type": "city",
    "id": "46",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-46-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-46-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-46-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438549-crimson-scales-ce-city-46-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438543-crimson-scales-ce-city-46-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438540-crimson-scales-ce-city-46-outb.mp3"
  },
  {
    "type": "city",
    "id": "47",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-47-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-47-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-47-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438537-crimson-scales-ce-city-47-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438528-crimson-scales-ce-city-47-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438525-crimson-scales-ce-city-47-outb.mp3"
  },
  {
    "type": "city",
    "id": "48",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-48-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-48-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-48-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438519-crimson-scales-ce-city-48-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438513-crimson-scales-ce-city-48-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438510-crimson-scales-ce-city-48-outb.mp3"
  },
  {
    "type": "city",
    "id": "49",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-49-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-49-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-49-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438501-crimson-scales-ce-city-49-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438498-crimson-scales-ce-city-49-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438492-crimson-scales-ce-city-49-outb.mp3"
  },
  {
    "type": "city",
    "id": "50",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-50-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-50-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-50-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438486-crimson-scales-ce-city-50-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438480-crimson-scales-ce-city-50-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438474-crimson-scales-ce-city-50-outb.mp3"
  },
  {
    "type": "city",
    "id": "51",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-51-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-51-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-51-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438468-crimson-scales-ce-city-51-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438462-crimson-scales-ce-city-51-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438456-crimson-scales-ce-city-51-outb.mp3"
  },
  {
    "type": "city",
    "id": "52",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-52-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-52-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-52-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438453-crimson-scales-ce-city-52-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438447-crimson-scales-ce-city-52-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438438-crimson-scales-ce-city-52-outb.mp3"
  },
  {
    "type": "city",
    "id": "53",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-53-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-53-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-53-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438432-crimson-scales-ce-city-53-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438429-crimson-scales-ce-city-53-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438420-crimson-scales-ce-city-53-outb.mp3"
  },
  {
    "type": "city",
    "id": "54",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-54-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-54-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-54-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438417-crimson-scales-ce-city-54-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438414-crimson-scales-ce-city-54-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438408-crimson-scales-ce-city-54-outb.mp3"
  },
  {
    "type": "city",
    "id": "55",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-55-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-55-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-55-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438399-crimson-scales-ce-city-55-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438393-crimson-scales-ce-city-55-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438390-crimson-scales-ce-city-55-outb.mp3"
  },
  {
    "type": "city",
    "id": "56",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-56-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-56-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-56-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438387-crimson-scales-ce-city-56-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438381-crimson-scales-ce-city-56-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438375-crimson-scales-ce-city-56-outb.mp3"
  },
  {
    "type": "city",
    "id": "57",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-57-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-57-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-57-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438369-crimson-scales-ce-city-57-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438357-crimson-scales-ce-city-57-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438351-crimson-scales-ce-city-57-outb.mp3"
  },
  {
    "type": "city",
    "id": "58",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-58-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-58-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-58-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438345-crimson-scales-ce-city-58-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438342-crimson-scales-ce-city-58-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438336-crimson-scales-ce-city-58-outb.mp3"
  },
  {
    "type": "city",
    "id": "59",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-59-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-59-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-59-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438324-crimson-scales-ce-city-59-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438318-crimson-scales-ce-city-59-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438315-crimson-scales-ce-city-59-outb.mp3"
  },
  {
    "type": "city",
    "id": "60",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-60-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-60-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-60-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438312-crimson-scales-ce-city-60-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438309-crimson-scales-ce-city-60-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438306-crimson-scales-ce-city-60-outb.mp3"
  },
  {
    "type": "city",
    "id": "aa-01",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-aa-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-aa-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-aa-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438852-crimson-scales-ce-city-aa-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438849-crimson-scales-ce-city-aa-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438846-crimson-scales-ce-city-aa-01-outb.mp3"
  },
  {
    "type": "city",
    "id": "aa-02",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-aa-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-aa-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-aa-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438843-crimson-scales-ce-city-aa-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438840-crimson-scales-ce-city-aa-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438837-crimson-scales-ce-city-aa-02-outb.mp3"
  },
  {
    "type": "city",
    "id": "qa-01",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438831-crimson-scales-ce-city-qa-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438828-crimson-scales-ce-city-qa-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438825-crimson-scales-ce-city-qa-01-outb.mp3"
  },
  {
    "type": "city",
    "id": "qa-02",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438822-crimson-scales-ce-city-qa-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438819-crimson-scales-ce-city-qa-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438816-crimson-scales-ce-city-qa-02-outb.mp3"
  },
  {
    "type": "city",
    "id": "qa-03",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-03-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-03-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-qa-03-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438813-crimson-scales-ce-city-qa-03-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438807-crimson-scales-ce-city-qa-03-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438801-crimson-scales-ce-city-qa-03-outb.mp3"
  },
  {
    "type": "city",
    "id": "rm-01",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-rm-01-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-rm-01-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-rm-01-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438795-crimson-scales-ce-city-rm-01-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438783-crimson-scales-ce-city-rm-01-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438777-crimson-scales-ce-city-rm-01-outb.mp3"
  },
  {
    "type": "city",
    "id": "rm-02",
    "frontUrl": "https://soundcloud.com/crimson-scales-ce/city-rm-02-front",
    "outaUrl": "https://soundcloud.com/crimson-scales-ce/city-rm-02-outa",
    "outbUrl": "https://soundcloud.com/crimson-scales-ce/city-rm-02-outb",
    "frontDirectUrl": "https://feeds.soundcloud.com/stream/1273438774-crimson-scales-ce-city-rm-02-front.mp3",
    "outaDirectUrl": "https://feeds.soundcloud.com/stream/1273438771-crimson-scales-ce-city-rm-02-outa.mp3",
    "outbDirectUrl": "https://feeds.soundcloud.com/stream/1273438768-crimson-scales-ce-city-rm-02-outb.mp3"
  }
];