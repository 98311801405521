import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, SxProps, Tab, Theme, Typography } from '@mui/material';
import { useLocalStorage } from 'usehooks-ts';
import './App.css';
import AudioButton from './AudioButton';
import AudioControls from './AudioControls';
import { cityEvents, CsEvent, roadEvents, scenarios } from './data';
import EventGridItem from './EventGridItem';
import { AudioButtonData } from './events';
import ScenarioGridItem from './ScenarioGridItem';

const introData: AudioButtonData = {
  name: 'Introduction',
  url: 'https://soundcloud.com/crimson-scales/introduction',
  directUrl: 'https://feeds.soundcloud.com/stream/1273476811-crimson-scales-introduction.mp3'
}

function App() {
  // hooks -----------------------------------------
  const [tabIdx, setTabIdx] = useLocalStorage('tab-idx', '1');
  // ---------------------------------------------------

  // sx -----------------------------------------
  const tabSx: SxProps<Theme> = { fontSize: "calc(10px + 1vmin)" };
  const logoSx: SxProps<Theme> = {
    paddingTop: "calc(20px + 2vw)",
    width: 'calc(160px + 50vw)',
    userSelect: 'none',
  };
  const subTitleSx: SxProps<Theme> = {
    fontSize: 'calc(1vw + 10px)',
    fontWeight: '700',
    userSelect: 'none',
  };
  const introButtonSx: SxProps<Theme> = {
    p: 3,
    pb: "calc(20px + 2vw)",
  };
  // ---------------------------------------------------

  // components -----------------------------------------
  const events = (eventList: CsEvent[]) => {
    return eventList.map(event => (
      <EventGridItem key={`${event.type}-${event.id}}`} event={event} />
    ));
  }
  // ---------------------------------------------------

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box className="ca-content">
        <Box sx={logoSx} component="img" src='title.png'></Box>
        <Typography variant='h1' sx={subTitleSx}>Audio Files</Typography>
        <Box sx={introButtonSx}>
          <AudioButton data={introData}>
            Introduction
          </AudioButton>
        </Box>       
        <AudioControls />
        <Box sx={{ width: 'calc(70vw + 100px)', paddingBottom: '70px' }}>
          <TabContext value={tabIdx}>
            <Box sx={{ borderBottom: 2 }}>
              <TabList value={tabIdx} onChange={(event, newIdx) => setTabIdx(newIdx)}>
                <Tab sx={tabSx} className='ca-tab' label="Scenarios" value="1" />
                <Tab sx={tabSx} className='ca-tab' label="City Events" value="2" />
                <Tab sx={tabSx} className='ca-tab' label="Road Events" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container spacing={3}>
                {scenarios.map(scenario => (
                  <ScenarioGridItem key={`${scenario.id}`} scenario={scenario} />
                ))}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid container spacing={3}>
                {events(cityEvents)}
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Grid container spacing={3}>
                {events(roadEvents)}
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}

export default App;
