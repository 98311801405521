import { Box, ButtonGroup, Card, CardContent, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

interface GridItemProps {
  title: string,
  icon?: string,
  children: ReactNode,
}

function BaseCard({ title, icon, children }: GridItemProps) {
  const iconSx: SxProps<Theme> = {
    width: "32px",
    mb: "-8px"
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ paddingBottom: "16px" }} variant="h5" component="span">
            {title}
          </Typography>
          { icon && <Box component="img" sx={iconSx} src={icon}></Box>}
        </Box>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          {children}
        </ButtonGroup>
      </CardContent>
    </Card>
  );
}

export default BaseCard;