import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Alert, Fab, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Snackbar, SxProps, TextField, Theme } from "@mui/material";
import { Box } from "@mui/system";
import copy from "copy-to-clipboard";
import { ChangeEvent, useEffect, useState } from "react";
import Player, { PlayerInterface, Track } from "react-material-music-player";
import ActionCreators from "react-material-music-player/dist/redux/actionCreators";
import playerStore from 'react-material-music-player/dist/redux/store';
import { useScrollYPosition } from 'react-use-scroll-position';
import { useEventListener, useLocalStorage } from "usehooks-ts";
import * as C from "./constants";
import { AudioButtonData, AUDIO_BUTTON_CLICK_EVENT, EventBus } from "./events";

function AudioControls() {
  const [mode, setMode] = useLocalStorage('mode', C.PLAYER_MODE);
  const [bot, setBot] = useLocalStorage('bot', C.BONGO_BOT);
  const [botCommand, setBotCommand] = useLocalStorage('bot-command', C.BOT_COMMANDS.get(C.BONGO_BOT) || '');
  const [msgOpen, setMsgOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const scrollY = useScrollYPosition();

  useEffect(() => {
    const handleAudioButtonClick: EventListener = e => {
      const data = EventBus.detail(e) as AudioButtonData;
      if (mode === C.PLAYER_MODE) {
        const track = new Track(data.name, "", data.name, "", data.directUrl);
        PlayerInterface.play([track]);
      } else if (mode === C.BOT_MODE) {
        const command = botCommand ? botCommand + " " : "";
        copy(`${command}${data.url}`);
        setMsg(`${data.name} Command Copied to Clipboard.`);
        setMsgOpen(true);
      }
    }
    EventBus.on(AUDIO_BUTTON_CLICK_EVENT, handleAudioButtonClick);
    return () => EventBus.remove(AUDIO_BUTTON_CLICK_EVENT, handleAudioButtonClick);
  });

  useEventListener('keydown', (event) => {
    const code = event.code;
    if(![C.KEY_CODE_BACKSPACE, C.KEY_CODE_ESCAPE, C.KEY_CODE_SPACE].includes(code)) return;
    if(mode !== C.PLAYER_MODE) return;
    
    if([C.KEY_CODE_BACKSPACE, C.KEY_CODE_ESCAPE].includes(code)) {
      playerStore.dispatch(ActionCreators.stop());
    } else {
      event.stopPropagation();
      event.preventDefault();
      const playerState = playerStore.getState();
      if(playerState.mediaState === "PLAYING") {
        playerStore.dispatch(ActionCreators.pause());
      } else {
        playerStore.dispatch(ActionCreators.play());
      }
    }
  });

  const fabSx: SxProps<Theme> = {
    right: 'calc(10px + 0.5vw)',
    bottom: mode === C.PLAYER_MODE ? 'calc(70px + 0.5vw)' : 'calc(10px + 0.5vw)',
    position: 'fixed',
  };
  const playerSx: SxProps<Theme> = {
    zIndex: '10',
    position: 'fixed',
    bottom: '0',
    left: '0',
  };

  const handleModeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMode(e.target.value);
    setMsgOpen(false);
    playerStore.dispatch(ActionCreators.pause());
  }
  const handleBotChange = (e: SelectChangeEvent<string>) => {
    const newBot: string = e.target.value;
    setBot(newBot);
    if (newBot === C.CUSTOM_BOT) {
      setBotCommand('');
    } else {
      const newCommand = C.BOT_COMMANDS.get(newBot) || "";
      setBotCommand(newCommand);
    }
  }
  const handleBotCommandChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBotCommand(e.target.value);
  }

  const botForm = () => {
    if (mode !== C.BOT_MODE) return null;
    return (
      <Box sx={{ pb: 1 }}>
        <FormControl variant="standard" sx={{ m: 2, width: 130 }}>
          <InputLabel>Discord Bot</InputLabel>

          <Select value={bot} label="Discord Bot" onChange={handleBotChange}>
            <MenuItem value={C.BONGO_BOT}>Bongo</MenuItem>
            <MenuItem value={C.CHIP_BOT}>Chip</MenuItem>
            <MenuItem value={C.FRED_BOAT_BOT}>FredBoat♪♪</MenuItem>
            <MenuItem value={C.HYDRA_BOT}>Hydra</MenuItem>
            <MenuItem value={C.GREEN_BOT}>Green-bot</MenuItem>
            <MenuItem value={C.JOCKIE_MUSIC_BOT}>Jockie Music</MenuItem>
            <MenuItem value={C.MAKI_BOT}>Maki</MenuItem>
            <MenuItem value={C.PANCAKE_BOT}>Pancake</MenuItem>
            <MenuItem value={C.PRO_BOT}>ProBot</MenuItem>
            <MenuItem value={C.SOUL_MUSIC_BOT}>Soul Music</MenuItem>
            <MenuItem value={C.CUSTOM_BOT}>Other...</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="standard" sx={{ m: 2, width: 130 }}
          label="Command" disabled={bot !== C.CUSTOM_BOT}
          value={botCommand} onChange={handleBotCommandChange}
        />
      </Box>
    );
  }

  return (
    <Box>
      <FormControl>
        <RadioGroup row value={mode} onChange={handleModeChange}>
          <FormControlLabel value={C.PLAYER_MODE} control={<Radio />} label="Player" />
          <FormControlLabel value={C.BOT_MODE} control={<Radio />} label="Discord Bot Command" />
        </RadioGroup>
      </FormControl>
      {botForm()}
      <Snackbar
        open={msgOpen} autoHideDuration={5000}
        onClose={(e, reason) => reason !== 'clickaway' && setMsgOpen(false)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
        <Alert severity="success" sx={{ width: '100%' }}>{msg}</Alert>
      </Snackbar>
      {scrollY > 600 &&
        <Fab sx={fabSx} color="primary" onClick={() => window.scrollTo(0, 0)}>
          <ArrowUpwardIcon />
        </Fab>
      }
      {mode === C.PLAYER_MODE && <Player disableDrawer={true} sx={playerSx} />}
    </Box>
  );
}

export default AudioControls;